<div class="flex flex-col w-full ">

  <div class="flex items-center justify-between">
    <div class="">
      <div
        *ngIf="editMode || denyMode"
        class="text-xs md:text-lg font-semibold text-white"
      >
        Speichern Sie Ihre Änderungen
      </div>

    </div>

    <div class="flex gap-4 justify-end ">
      <button
        *ngIf="!editMode && !denyMode"
        class="text-gray-600 font-bold border rounded-full px-2 py-1 hover:bg-gray-400 hover:text-white hover:font-bold"
        (click)="denyModeOn.emit()"
      >
        Einwilligung widerrufen
      </button>
      <button
        *ngIf="editMode && !denyMode"
        class="text-toyotaRed bg-white font-bold border rounded-full px-2 py-1 hover:bg-gray-400 hover:text-white hover:font-bold"
        (click)="save.emit()"
      >
        {{ willDenyCC || willDenyCE ? "Sperren" : "Speichern" }}
      </button>

      <button
        *ngIf="denyMode"
        class="text-toyotaRed bg-white font-bold border rounded-full px-2 py-1 hover:bg-gray-400 hover:text-white hover:font-bold"
        (click)="save.emit()"
      >
        Speichern
      </button>

      <button
        *ngIf="editMode || denyMode"
        class="bg-white text-gray-600 font-bold border rounded-full px-2 py-1 hover:bg-gray-400 hover:text-white hover:font-bold"
        (click)="cancel.emit()"
      >
        Abbrechen
      </button>
    </div>
  </div>
  <div class="font-semibold text-xs md:text-lg text-white text-right mt-2">
    <p *ngIf="willDenyCE && willDenyCC && editMode">
      Es sind keine Kontaktkanäle und -anlässe freigegeben.
    </p>

    <p *ngIf="willDenyCC && !willDenyCE && editMode" class="relative right-[7px]">
      Es sind keine Kontaktkanäle freigegeben.
    </p>
    <p *ngIf="willDenyCE && !willDenyCC && editMode">
      Es sind keine Kontaktanlässe freigegeben.
    </p>
  </div>
</div>

<div class="mt-4 mb-2 customer-client-header">
  Alle Vertragspartner für die Ihre Einwilligung gültig ist:
</div>

<div class="flex flex-wrap items-start w-full">
  <div
    *ngFor="let groupDealer of api.groupDealerList; let i = index"
    class="flex flex-row items-start w-full"
  >
    <div
      class="relative top-[5px] flex items-start gap-2 mx-4 md:ml-[0px] md:w-8 w-8"
    >
      <input
        type="checkbox"
        class="w-4 h-4 accent-toyotaRed"
        [checked]="selectedDealers[i]"
        (change)="toggleDealerSelection(i, groupDealer)"
      />
    </div>
    <div class="items-start font-light text-md md:text-lg flex flex-row">
      <div class="">
        {{ groupDealer.fullCompanyName || groupDealer.name || "" }}
      </div>
    </div>
  </div>
</div>

import {map, Observable} from "rxjs";
import {Country} from "@app/domain/core/country.model";
import {BaseStep} from "@app/domain/usecases/base/base-step";

export interface LoadCountryStepDependencies {
  countryString: Observable<string>
}

export class LoadCountryStep extends BaseStep<LoadCountryStepDependencies> {

  provide(): Observable<Country> {
    return this.dependencies.countryString.pipe(map(Country.from))
  }

  run(block: (country: Country) => Country) {
    this.provide().subscribe(block)
  }
}

import {Component, Input} from '@angular/core';
import {VehicleBrand} from "@app/domain/core/vehicle-brand.model";
import {LegalEntity} from "@app/domain/core/legal-entity.model";
@Component({
  selector: 'app-footer',
  standalone: true,
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  @Input() legalEntity: LegalEntity = LegalEntity.ToyotaGermany
  get year() {
    return new Date().getFullYear();
  }
}

import {Observable, Subject} from "rxjs";
import {ContactViewModel} from "@app/domain/presenter/viewmodel/contactViewModel";
import {LegalEntity} from "@app/domain/core/legal-entity.model";
import {Country} from "@app/domain/core/country.model";
import {VehicleBrand} from "@app/domain/core/vehicle-brand.model";
import {LegalEntityFeature, LegalEntityFeatureDependencies} from "@app/domain/usecases/features/legal-entity.feature";
import {BaseController} from "@app/domain/presenter/base/base.controller";

export interface ContactControllerDependencies extends LegalEntityFeatureDependencies{
  brandString: Observable<string>
  countryString: Observable<string>
}

export class ContactController extends BaseController<ContactViewModel> {

  legalEntityFeature: LegalEntityFeature

  constructor(public dependencies: ContactControllerDependencies) {
    super()
    this.viewModel = new ContactViewModel(new LegalEntity(Country.DE, VehicleBrand.TOYOTA))
    this.legalEntityFeature = new LegalEntityFeature(dependencies)
  }

  override onInit() {
    this.legalEntityFeature.run((legalEntity => {
      this.viewModel.legalEntity = legalEntity
      this.push()
    }))
    super.onInit();
  }
}

<div *ngIf="showLabel" class="text-lg mt-10">Bevorzugten Vertragspartner auswählen</div>

<div class="flex flex-row gap-8 mt-2 pb-2 border-b border-b-1 border-{{textColor}}">
  <svg
    class="fill-toyotaRed stroke-toyotaRed"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_403_1537"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="3"
      y="3"
      width="23"
      height="23"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.375 17.5H18.3875L18.0375 17.1625C19.2625 15.7375 20 13.8875 20 11.875C20 7.3875 16.3625 3.75 11.875 3.75C7.3875 3.75 3.75 7.3875 3.75 11.875C3.75 16.3625 7.3875 20 11.875 20C13.8875 20 15.7375 19.2625 17.1625 18.0375L17.5 18.3875V19.375L23.75 25.6125L25.6125 23.75L19.375 17.5ZM11.875 17.5C8.7625 17.5 6.25 14.9875 6.25 11.875C6.25 8.7625 8.7625 6.25 11.875 6.25C14.9875 6.25 17.5 8.7625 17.5 11.875C17.5 14.9875 14.9875 17.5 11.875 17.5Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_403_1537)">
      <rect x="-16.25" y="-16.25" width="62.5" height="62.5" />
      <mask
        id="mask1_403_1537"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="-17"
        y="-17"
        width="64"
        height="64"
      >
        <rect x="-16.25" y="-16.25" width="62.5" height="62.5" fill="white" />
      </mask>
      <g mask="url(#mask1_403_1537)"></g>
    </g>
  </svg>

  <!--  <input-->
  <!--    #textInput-->
  <!--    class="w-full p-2 accent-toyotaRed"-->
  <!--    type="text"-->
  <!--    placeholder="Name, Straße, Postleitzahl oder Ort eingeben"-->
  <!--    (input)="onDealerSearchInput(textInput.value)"-->
  <!--    (focus)="onFocus()"-->
  <!--    (blur)="onBlur()"-->
  <!--  />-->
  <input
    #textInput
    class="w-full p-2 accent-toyotaRed"
    type="text"
    placeholder="Name, Straße, Postleitzahl oder Ort eingeben"
    (input)="onDealerSearchInput(textInput.value)"
  />
</div>

<div class="flex flex-col gap-4 items-start mt-8 font-thin text-lg">
  <div *ngFor="let dealerWithGroup of dealerList" class="flex flex-row gap-8">
    <svg
      class="w-6 h-8 hover:cursor-pointer"
      (click)="select(dealerWithGroup)"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1_1240"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="34"
        height="34"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.6667 11.6667H18.3333V18.3334H11.6667V21.6667H18.3333V28.3334H21.6667V21.6667H28.3333V18.3334H21.6667V11.6667V11.6667ZM20 3.33337C10.8 3.33337 3.33334 10.8 3.33334 20C3.33334 29.2 10.8 36.6667 20 36.6667C29.2 36.6667 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33337 20 3.33337V3.33337ZM20 33.3334C12.65 33.3334 6.66668 27.35 6.66668 20C6.66668 12.65 12.65 6.66671 20 6.66671C27.35 6.66671 33.3333 12.65 33.3333 20C33.3333 27.35 27.35 33.3334 20 33.3334V33.3334Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1_1240)">
        <rect
          x="-21.6667"
          y="-21.6666"
          width="83.3333"
          height="83.3333"
          fill="red"
        />
        <mask
          id="mask1_1_1240"
          style="mask-type: luminance"
          maskUnits="userSpaceOnUse"
          x="-22"
          y="-22"
          width="84"
          height="84"
        >
          <rect
            x="-21.6667"
            y="-21.6666"
            width="83.3333"
            height="83.3333"
            fill="red"
          />
        </mask>
        <g mask="url(#mask1_1_1240)"></g>
      </g>
    </svg>
    <div class="flex flex-col items-start">
      <div class="">{{ dealerWithGroup.name }}</div>
      <div class="">{{ dealerWithGroup.street }}</div>
      <div class="">
        {{ dealerWithGroup.postcode }} {{ dealerWithGroup.city }}
      </div>
    </div>
  </div>
</div>

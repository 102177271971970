<div class=" w-screen text-left pl-8 mt-8 bg-background flex justify-center">
  (c) {{ year }} {{legalEntity.vehicleBrand.brandName()}} |&nbsp;

  <a class="underline"
     [href]="legalEntity.vehicleBrand.impressum(legalEntity.country.isAustria)"
     target="_blank"
  >
     Impressum
  </a>
</div>

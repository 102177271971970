import {HttpHandlerFn, HttpRequest} from '@angular/common/http';

import {Credentials} from "@app/domain/core/credentials.model";

export  const credentialsInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {

    // Set auth header
    let credentials = localStorage.getItem(Credentials.credentialsKey);
    if (credentials) {
      req = req.clone({
        setHeaders: {
          Authorization: credentials
        }
      });
    }

    return next(req);
}

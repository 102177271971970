@if (legalEntity) {
  <div class="flex flex-col xl:flex-row">
    <div class="w-full xl:w-[50%] pr-2">
      <div class="mt-2">{{ legalEntity.name }}</div>
      <div class="">{{ legalEntity.department }}</div>
      <div class="">{{ legalEntity.city }}</div>
    </div>
    <div class="w-full xl:w-[50%]">
      <div class="mt-2">
        <a href="tel:{{legalEntity.phone}}">
          {{legalEntity.phone}}
        </a>
      </div>
      <div class="mt-6">
        <a href="mailto:{{legalEntity.email}}">
          {{legalEntity.email}}
          <fa-icon [icon]="faChevronRight"></fa-icon>
        </a>
      </div>
    </div>
  </div>
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import {ContactEvent, CustomerClientResponse} from "@app/domain/data/api-response.data";
import {EventIconComponent} from "@app/pages/customer/components/event-icon/event-icon.component";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-contact-channels-update',
  standalone: true,
  templateUrl: './contact-channels-update.component.html',
  imports: [
    CommonModule,
    EventIconComponent
  ]
})
export class ContactChannelsUpdateComponent {
  @Input() api: CustomerClientResponse;
  @Output() didChange = new EventEmitter<void>();
  change(contactEvent: ContactEvent, index: any) {
    if (contactEvent.isConfirmed()) {
      contactEvent.unconfirm();
    } else {
      contactEvent.confirm();
    }
    // console.log("Changed checkbox:", contactEvent, "At index:", index);
    this.didChange.emit(index);
  }
}

import {Component, Input} from '@angular/core';
import {VehicleBrand} from "@app/domain/core/vehicle-brand.model";

@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  @Input() brand: VehicleBrand
}

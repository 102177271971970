import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-activity-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './activity-spinner.component.html',
})
export class ActivitySpinnerComponent {

}

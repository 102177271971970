<div class="customer-client-header">Kontaktanlässe</div>
<div
  class="mt-2 flex flex-col items-start md:gap-4"
  (click)="closeTooltips($event)"
>
  <div
    *ngFor="let contactEvent of api.contactEventListSorted; index as i"
    class="contact-event-card flex items-center text-xs w-[100%]"
  >
    <div class="flex items-center relative tooltip-container">
      <input
        type="checkbox"
        class="w-4 h-4 accent-toyotaRed"
        [checked]="contactEvent.isConfirmed()"
        (change)="change(contactEvent, i)"
      />
      <app-event-icon
        [contactEvent]="contactEvent"
        [vertical]="true"
        (click)="toggleTooltip(contactEvent, $event)"
        (click)="change(contactEvent, i)"
        class="event-icon"
      />
      <p
        class="tooltip-text invisible w-[230px] bg-gray-400 text-white text-center rounded-md p-1.5 absolute z-20 top-1/2 left-full translate-x-[10px] translate-y-[-50%] opacity-0 transition-opacity duration-300"
        [class.tooltip-text-active]="contactEvent.showTooltip"
      >
        {{ contactEvent.customerDescription }}
      </p>
    </div>
    <div
      class="flex flex-row tooltip-container mobile-icon items-center justify-center bottom-[10px] relative h-auto"
      (click)="toggleTooltip(contactEvent, $event)"
    >
      <img
        src="././assets/icons/help_outline-24px.svg"
        class="ml-8 sm:ml-0 sm:relative sm:right-4 mt-5 w-4 h-4 mobile-only-img min-w-[18px] min-h-[18px] filter"
      />
      <p
        class="tooltip-text invisible w-[230px] bg-gray-400 text-white text-center rounded-md p-1.5 absolute z-20 top-1/2 left-full translate-x-[10px] translate-y-[-50%] opacity-0 transition-opacity duration-300"
        [class.tooltip-text-active]="contactEvent.showTooltip"
      >
        {{ contactEvent.customerDescription }}
      </p>
    </div>
  </div>
</div>

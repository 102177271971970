export class VehicleBrand {
  public static readonly TOYOTA_STRING = 'TOYOTA';
  public static readonly LEXUS_STRING = 'LEXUS';

  public name = 'Toyota'

  private toyota = true
  private impressumToyotaDEUrl = 'https://www.toyota.de/legal/impressum'
  private impressumToyotaATUrl = 'https://www.toyota.at/legal/impressum'
  private impressumLexusDEUrl = 'https://www.lexus.de/resources/impressum'
  private impressumLexusATUrl = 'https://www.lexus.at/resources/impressum'

  public static get TOYOTA() {
    return new VehicleBrand(VehicleBrand.TOYOTA_STRING);
  }

  public static get LEXUS() {
    return new VehicleBrand(VehicleBrand.LEXUS_STRING);
  }

  public static from(brandString: string): VehicleBrand {
    return new VehicleBrand(brandString)
  }

  constructor(
    public brandString: string
  ) {
    if (brandString && (brandString.length > 0) && brandString.toLowerCase().includes(VehicleBrand.LEXUS_STRING.toLowerCase())) {
      this.brandString = VehicleBrand.LEXUS_STRING
      this.toyota = false
      this.name = 'Lexus'
    } else {
      this.brandString = VehicleBrand.TOYOTA_STRING
      this.toyota = true
      this.name = 'Toyota'
    }
  }

  isToyota() {
    return this.toyota
  }

  isLexus() {
    return !this.toyota
  }

  brandName() {
    return this.name
  }

  get uriName(): string {
    if (this.isToyota()) {
      return "toyota"
    } else {
      return "lexus"
    }
  }

  get path(): string {
    return "/" + this.uriName
  }

  get nameForCountryBrand(): string {
    return this.name
  }

  // TODO move to LegalEntity
  impressum(at: boolean) {
    if (at && this.toyota) {
      return this.impressumToyotaATUrl;
    }

    if (at && !this.toyota) {
      return this.impressumLexusATUrl;
    }

    if (!at && !this.toyota) {
      return this.impressumLexusDEUrl;
    }

    return this.impressumToyotaDEUrl;
  }

  get favicon(): string {
    if (this.isLexus()) {
      return 'lexus-favicon.png';
    }
    return 'toyota-favicon.png';
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {CustomerClientResponse, Dealer, DealerWithGroup} from "@app/domain/data/api-response.data";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-group-dealer',
  standalone: true,
  templateUrl: './group-dealer.component.html',
  imports: [CommonModule]
})
export class GroupDealerComponent implements OnChanges {
  @Input() api: CustomerClientResponse;
  @Input() apiOriginal: CustomerClientResponse;
  @Input() editMode = false;
  @Output() didChange = new EventEmitter<void>();

  selectedDealers: boolean[] = [];
  previousPreferredDealer: Dealer | null = null;
  latestDealerNumber?: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['api']) {
      this.initializeSelection();
    }
  }

  initializeSelection() {
    if (this.api && this.api.groupDealerList) {
      this.selectedDealers = this.api.groupDealerList.map(() => true);
    }
  }

  toggleDealerSelection(index: number, groupDealer: Dealer) {
    this.selectedDealers[index] = !this.selectedDealers[index];
    if (this.selectedDealers[index]) {
      this.restorePreferredDealer(groupDealer);
    } else {
      this.handleUnselectingDealer(groupDealer);
    }
    this.didChange.emit();
  }

  handleUnselectingDealer(groupDealer: Dealer) {
    if (
      this.api.preferredDealer &&
      this.api.preferredDealer.dealerGroup === groupDealer.dealerNumber
    ) {
      this.previousPreferredDealer = this.api.preferredDealer;
      this.api.preferredDealer = null;
    } else if (
      this.api.preferredDealer &&
      this.apiOriginal.preferredDealer.dealerGroup !==
        groupDealer.dealerNumber &&
      this.api.preferredDealer.dealerGroup !==
        this.apiOriginal.preferredDealer.dealerGroup &&
      this.latestDealerNumber === groupDealer.dealerNumber
    ) {
      this.previousPreferredDealer = this.apiOriginal.preferredDealer;
      this.api.preferredDealer = null;
      // this.previousPreferredDealer = this.api.preferredDealer;
      // this.api.preferredDealer = null;
    }
  }

  restorePreferredDealer(groupDealer: Dealer) {
    if (
      (this.previousPreferredDealer &&
        this.previousPreferredDealer.dealerGroup ===
          groupDealer.dealerNumber) ||
      (this.previousPreferredDealer &&
        this.apiOriginal.preferredDealer.dealerGroup !==
          groupDealer.dealerNumber)
    ) {
      this.api.preferredDealer = this.previousPreferredDealer;
      this.previousPreferredDealer = null;
    }
  }

  saveChanges() {
    this.api.groupDealerList = this.api.groupDealerList.filter(
      (_, index) => this.selectedDealers[index],
    );
    this.didChange.emit();
    this.editMode = false;
  }

  onDealerAdded(dealerWithGroup: DealerWithGroup) {
    const dealer = Dealer.createDealerWithGroup(dealerWithGroup);
    if (!dealer.fullCompanyName) {
      dealer.fullCompanyName = dealer.name; // Ensure fullCompanyName is set if it is empty
    }
    this.api.groupDealerList.push(dealer); // Ensure the dealer is added to the list with fullCompanyName
    this.selectedDealers.push(true); // Automatically select the newly added dealer
    this.didChange.emit();
    let latestDealer =
      this.api.groupDealerList[this.api.groupDealerList.length - 1];
    this.latestDealerNumber = latestDealer.dealerNumber;
    console.log(latestDealer);
  }

  getSelectedDealerNumbers(): string[] {
    return this.api.groupDealerList
      .filter((_, index) => this.selectedDealers[index])
      .map((dealer) => dealer.dealerNumber);
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {CustomerClientResponse, Dealer, DealerWithGroup} from "@app/domain/data/api-response.data";
import {CommonModule} from "@angular/common";
import {Type} from "class-transformer";

export interface SetPreferredDealer {
  preferredDealer: Dealer;
}

@Component({
  selector: 'app-preferred-dealer-update',
  standalone: true,
  templateUrl: './preferred-dealer-update.component.html',
  imports: [CommonModule]
})
export class PreferredDealerUpdateComponent {
  @Input() api: SetPreferredDealer;
  @Input() groupDealerList: Dealer[];
  @Input() allDealerList: DealerWithGroup[];
  @Input() textColor = 'black'
  @Input() showLabel = true
  @Output() didChange = new EventEmitter<void>();
  @Output() dealerAdded = new EventEmitter<Dealer>();
  // @Output() searchInput = new EventEmitter<string>();
  // @Output() focusChange = new EventEmitter<boolean>();

  @ViewChild('textInput', { static: false }) inputField: ElementRef;

  dealerList: DealerWithGroup[] = [];

  clearInput() {
    this.inputField.nativeElement.value = '';
    this.dealerList = [];
  }

  onDealerSearchInput(input: string) {
    if (input.length > 2) {
      this.dealerList = this.filterDealerList(input);
    } else {
      this.dealerList = [];
    }
    // this.searchInput.emit(input);
  }

  private filterDealerList(input: string) {
    const inputArray = input.toLowerCase().match(/\S+/g);
    console.log('search dealers by keywords:', inputArray);
    return this.allDealerList.filter((dealerWithGroup) => {
      let dealerPropertiesArray = dealerWithGroup.name
        .toLowerCase()
        .match(/\S+/g)
        .concat(dealerWithGroup.street.toLowerCase().match(/\S+/g))
        .concat(dealerWithGroup.postcode.toLowerCase())
        .concat(dealerWithGroup.city.toLowerCase());
      return inputArray.every((i) =>
        dealerPropertiesArray.some((dp) => dp.includes(i)),
      );
    });
  }

  select(dealerWithGroup: DealerWithGroup) {
    this.api.preferredDealer = Dealer.createDealerWithGroup(dealerWithGroup);
    if (dealerWithGroup.groupName) {
      let dealer = Dealer.create(
        dealerWithGroup.groupName,
        dealerWithGroup.dealerGroup,
      );
      let incl = this.groupDealerList.find((d) => {
        return d.dealerNumber === dealer.dealerNumber;
      });
      console.log(dealer);

      if (!incl) {
        this.dealerAdded.emit(dealer); // Emit the dealerAdded event
      }
    }
    this.dealerList = [];
    this.didChange.emit();
  }

  // onFocus() {
  //   this.focusChange.emit(true);
  // }
  //
  // onBlur() {
  //   this.focusChange.emit(false);
  // }
}

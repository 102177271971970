import {Component, Input, OnInit} from '@angular/core';
import {ContactEvent} from "@app/domain/data/api-response.data";
import {VIconComponent} from "@app/shared/components/icons/v-icon/v-icon.component";
import {HIconComponent} from "@app/shared/components/icons/h-icon/h-icon.component";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-event-icon',
  standalone: true,
  imports: [
    CommonModule,
    VIconComponent,
    HIconComponent
  ],
  templateUrl: './event-icon.component.html'
})
export class EventIconComponent implements OnInit{

  @Input() contactEvent: ContactEvent
  @Input() vertical = true


  ngOnInit(): void {
    // this.icon = this.getIcon()
  }

  getActive(): string {
    return this.contactEvent.isConfirmed() ? 'active' : 'inactive'
  }

  get icon(): string {
    // console.log('getIcon()', this.contactEvent.eventName)
    switch (this.contactEvent.eventName) {
      case 'REMINDER':
        return `ic_erinnerungen_${this.getActive()}1x.png`
      case 'EVENTS':
        return `ic_events_${this.getActive()}1x.png`
      case 'SURVEYS':
        return `ic_befragungen_${this.getActive()}1x.png`
      case 'OFFERS':
        return `ic_angebote_${this.getActive()}1x.png`
      case 'post':
        return `ic_post_${this.getActive()}1x.png`
      case 'email':
        return `ic_mail_${this.getActive()}1x.png`
      case 'phone':
        return `ic_phone_${this.getActive()}1x.png`
      case 'messenger':
        return `ic_messenger_${this.getActive()}1x.png`
    }

    return 'ic_events_active1x.png'
  }

}

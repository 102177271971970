import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Dealer} from "@app/domain/data/api-response.data";
import {VehicleBrand} from "@app/domain/core/vehicle-brand.model";
import {CommonModule} from "@angular/common";
@Component({
  selector: 'app-preferred-dealer',
  standalone: true,
  templateUrl: './preferred-dealer.component.html',
  imports: [CommonModule]
})
export class PreferredDealerComponent {
  @Input() preferredDealer: Dealer
  @Input() brand: VehicleBrand
  @Input() editMode = false
  @Input() canChangeBrand = false

  openLexus() {
    window.location.href = '/customer/lexus'
  }

  openToyota() {
    window.location.href = '/customer/toyota'
  }
}

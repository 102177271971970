import {lastValueFrom, Observable} from "rxjs";
import {EmailHelper} from "@app/shared/utils/EmailHelper";
import {VehicleBrand} from "@app/domain/core/vehicle-brand.model";

export type LookupFunction = ((email: string, token: string, brand: VehicleBrand) => Observable<void>)

export interface LookupFeatureDependencies {
  lookup: LookupFunction,
  token$: Observable<string>
}

export interface LookupFeatureInput {
  email: string
  brand: VehicleBrand
}

export class LookupFeature {

  token: string

  constructor(
    private dependencies: LookupFeatureDependencies,
  ) {
  }

  async onInit() {
    // Must be done here
    this.token = await lastValueFrom(this.dependencies.token$)
  }

  async run(input: LookupFeatureInput): Promise<boolean> {
    if (this.token && EmailHelper.isEmail(input.email)) {
      await lastValueFrom(this.dependencies.lookup(input.email, this.token, input.brand))
      return true
    } else {
      return false
    }
  }
}

import {LegalEntity} from "@app/domain/core/legal-entity.model";
import {of, forkJoin, map, pipe, Observable, tap, combineLatest, take} from 'rxjs'
import {
  LoadVehicleBrandStep,
  LoadVehicleBrandStepDependencies
} from "@app/domain/usecases/steps/load-vehicle-brand.step";
import {LoadCountryStep, LoadCountryStepDependencies} from "@app/domain/usecases/steps/load-country.step";
import {LegalEntities} from "@app/domain/core/legal-entities";
import {Country} from "@app/domain/core/country.model";
import {VehicleBrand} from "@app/domain/core/vehicle-brand.model";

export interface LegalEntityFeatureDependencies extends LoadCountryStepDependencies, LoadVehicleBrandStepDependencies{
  brandString: Observable<string>
  countryString: Observable<string>
}

export class LegalEntityFeature {

  private providers: [Observable<VehicleBrand>, Observable<Country>]

  constructor(
    public dependencies: LegalEntityFeatureDependencies) {
    this.providers = [
      new LoadVehicleBrandStep(dependencies).provide(),
      new LoadCountryStep(dependencies).provide()
    ]
  }

  mapToLegalEntity = (val: [VehicleBrand, Country]) => {
    return LegalEntity.from(val[0], val[1])
  }

  provide(): Observable<LegalEntity> {
    return combineLatest(this.providers).pipe(
      map(this.mapToLegalEntity),
    )
  }

  run(block: ((legalEntity: LegalEntity) => void)) {
    return this.provide().subscribe(block)
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {CustomerService} from "@app/shared/services/customer.service";
import {CustomerClientResponse} from "@app/domain/data/api-response.data";
import {VehicleBrand} from "@app/domain/core/vehicle-brand.model";
import {HeadComponent} from "@app/shared/components/head/head.component";
import {CarImageComponent} from "@app/shared/components/car-image/car-image.component";
import {LegalEntity} from "@app/domain/core/legal-entity.model";
import {Country} from "@app/domain/core/country.model";
import {environment} from "@src/environments/environment";
import {ActivatedRoute} from "@angular/router";
import {ContactComponent} from "@app/shared/components/contact/contact.component";
import {ActivitySpinnerComponent} from "@app/shared/components/activity-spinner/activity-spinner.component";
import {FooterComponent} from "@app/shared/components/footer/footer.component";
import {HeaderComponent} from "@app/shared/components/header/header.component";

@Component({
  selector: 'app-doi',
  standalone: true,
  templateUrl: './doi.component.html',
  imports: [CommonModule, HeadComponent, CarImageComponent, ContactComponent, ActivitySpinnerComponent, FooterComponent, HeaderComponent],
})
export class DoiComponent {

  legalEntity: LegalEntity = LegalEntity.ToyotaGermany
  verificationToken: string
  done = false
  success = false
  denyAction = false
  confirmInProgress = false
  denyInProgress = false

  constructor(
    private  customerService: CustomerService,
    private route: ActivatedRoute,
    ) {
    this.route.queryParams.subscribe(params => {
      this.denyAction = (params['action'] === 'deny')

    })
    this.route.params.subscribe(params => {
      const brand = VehicleBrand.from(params['brand'])
      this.legalEntity = LegalEntity.from(brand, Country.from(environment.country))
      this.verificationToken = params['verificationtoken']
    })
  }

  get focusConfirm():boolean {
    return (this.confirmInProgress || !this.denyAction)
  }

  get focusDeny():boolean {
    return (this.denyInProgress || this.denyAction)
  }

  get inProgress():boolean {
    return (this.denyInProgress || this.confirmInProgress)
  }

  confirm() {
    this.confirmInProgress = true
    this.customerService
      .confirmCustomerConsent(this.legalEntity.vehicleBrand, this.verificationToken)
      .subscribe((success) => {
        this.success = success
        this.done = true
        this.confirmInProgress = false
      });
  }

  deny() {
    this.denyInProgress = true
    this.customerService
      .denyCustomerConsent(this.legalEntity.vehicleBrand, this.verificationToken)
      .subscribe((success) => {
        this.denyInProgress = false
        this.success = success
        this.done = true
      });
  }
}

import {Subject} from "rxjs";
export abstract class BaseController<T>{
  viewModel$ = new Subject<T>()
  viewModel: T

  onInit() {
    this.push()
  }

  push() {
    this.viewModel$.next(this.viewModel)
  }
}


<div class="flex flex-row items-center justify-between">
  <div class="customer-client-header" *ngIf="brand.isToyota()">
    Ihr bevorzugter Toyota Vertragspartner
  </div>
  <div class="customer-client-header" *ngIf="brand.isLexus()">
    Ihr bevorzugter Lexus Vertragspartner
  </div>
  <div
    *ngIf="brand.isToyota() && !editMode && canChangeBrand"
    class="underline cursor-pointer"
    (click)="openLexus()"
  >
    Zu Lexus
  </div>
  <div
    *ngIf="brand.isLexus() && !editMode && canChangeBrand"
    class="underline cursor-pointer"
    (click)="openToyota()"
  >
    Zu Toyota
  </div>
</div>

<div *ngIf="preferredDealer" class="my-2">
  Ihr bevorzugter Vertragspartner ist der Vertragspartner von dem Sie die
  wichtigsten Informationen und Angebote rund um Ihr Fahrzeug erhalten.
</div>

<div
  *ngIf="!preferredDealer"
  class="mt-4 font-semibold bg-toyotaRed text-white p-4"
>
  Sie haben aktuell keinen bevorzugten Vertragspartner. Bitte wählen Sie einen
  bevorzugten Vertragspartner aus.
</div>

<div class="flex flex-row items-center mt-4" *ngIf="preferredDealer">
  <div class="md:w-14 mr-4 relative w-8" *ngIf="brand.isToyota()">
    <img src="/assets/images/bevVP-Toyota.png" />
  </div>
  <div class="md:w-14 mr-4 relative w-8" *ngIf="brand.isLexus()">
    <img src="/assets/images/bevVP-Lexus.png" />
  </div>
  <div class="items-start font-light text-md md:text-lg w-[70%]">
    <div class="">{{ preferredDealer.name }}</div>
    <div class="">{{ preferredDealer.street }}</div>
    <div class="">
      {{ preferredDealer.postcode }} {{ preferredDealer.city }}
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {environment} from "@src/environments/environment";
import {VehicleBrand} from "@app/domain/core/vehicle-brand.model";
import {CustomerClientResponse, DealerInformation} from "@app/domain/data/api-response.data";
import {Credentials} from "@app/domain/core/credentials.model";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  getCustomerConsent(brand: VehicleBrand): Observable<CustomerClientResponse>  {
    if (localStorage.getItem(Credentials.credentialsKey)) {
      return this.http.get<CustomerClientResponse>(environment.endpoints.getCustomerConsent + brand.path);
    } else {
      return of(null)
    }
  }

  updateCustomerConsent(brand: VehicleBrand, api: CustomerClientResponse): Observable<boolean> {
    api.allDealerList = []
    return this.http.put<boolean>(environment.endpoints.getCustomerConsent + brand.path, api);
  }

  confirmCustomerConsent(vehicleBrand: VehicleBrand, verificationtoken: string): Observable<boolean> {
    let url = environment.endpoints.confirmCustomerConsent
      .replace('{brand}', vehicleBrand.uriName)
      .replace('{verificationtoken}', verificationtoken)

    return this.http.get<boolean>(url);
  }

  denyCustomerConsent(vehicleBrand: VehicleBrand, verificationtoken: string): Observable<boolean> {
    let url = environment.endpoints.denyCustomerConsent
      .replace('{brand}', vehicleBrand.uriName)
      .replace('{verificationtoken}', verificationtoken)
    return this.http.get<boolean>(url);
  }

  getDealerInformation(brand: VehicleBrand): Observable<DealerInformation> {
    return this.http.get<DealerInformation>(environment.endpoints.getCustomerConsent + '/dealerInfo' + brand.path);
  }

  registerCustomer(
    brand: VehicleBrand,
    firstname: string,
    lastname: string,
    email: string
  ): Observable<boolean> {
    return this.http.post<boolean>(environment.endpoints.getCustomerConsent + '/new' + brand.path, {
      firstname: firstname,
      lastname: lastname,
      email: email,
    });
  }
}

<app-header
  *ngIf="brand"
  class="{{editMode ? 'hidden md:flex' : ''}}"
  [brand]="brand"
/>
<div
  *ngIf="brand"
  class="mx-8 md:mx-32 md:mt-6 pt-0 md:pt-8 flex flex-wrap items-center rounded-xl shadow  bg-white h-full {{ editMode ? 'hidden md:flex' : ''}}"
>
  <img
    src="/assets/images/{{
            brand.isLexus() ? 'lexus-logo.png' : 'toyota-logo.png'
          }}"
    class="hidden md:flex md:w-[150px] md:p-8 "
  />
  <div class="mt-4 md:ml-8 md:mt-8 lg:mt-0 xl:ml-4 w-full md:w-9/12  ">
    <div class="flex gap-2 text-xl lg:text-3xl px-4 md:px-0">
      <img
        src="/assets/images/{{
            brand.isLexus() ? 'lexus-logo.png' : 'toyota-logo.png'
          }}"
        class="w-[70px] object-scale-down p-1 md:hidden"
      />
      <ng-content select="[title]" />
    </div>
    <p class="mt-2 px-8 md:px-0 pb-8">
      <ng-content select="[text]" />
    </p>
  </div>

  <ng-content select="[innerextra]"></ng-content>

</div>
<ng-content select="[extra]"></ng-content>

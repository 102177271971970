<app-head
  [brand]="brand"
  [editMode]="editMode"
>
  <ng-container title>
    <div class="hidden md:flex">
      IHRE KONTAKTEINSTELLUNGEN {{ api?.name ?? '' }}
    </div>
    <div class=" md:hidden">
      {{ api?.name ?? '' }}
    </div>
  </ng-container>
  <ng-container text>
    Hier können Sie festlegen, zu welchen Kontaktanlässen und über
    welche Kontaktkanäle wir Sie kontaktieren dürfen. Außerdem können
    Sie auswählen, welche {{ brand?.name ?? 'Toyota'}} Vertragspartner Sie kontaktieren dürfen
    und welcher davon Ihr bevorzugter Vertragspartner ist.
  </ng-container>

  <ng-container *ngIf="!editMode && api && brand && api.active" innerextra>
    <div class="p-4 w-full rounded-xl flex justify-end">
      <button
        class="text-gray-600 font-bold border rounded-full px-2 py-1 hover:bg-gray-400 hover:text-white hover:font-bold"
        (click)="denyModeOn()"
      >
        Einwilligung widerrufen
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="editMode" extra>
    <div
      *ngIf="api && brand && api.active"
      class="mx-8 md:mx-32 mt-4 p-4 rounded-xl hidden md:flex sticky top-0 z-10 {{
          editMode
            ? 'bg-toyotaRed'
            : 'bg-white'
        }}"
    >
      <div class="w-full">
        <app-action-buttons
          [editMode]="editMode"
          [denyMode]="denyMode"
          (edit)="edit()"
          (denyModeOn)="denyModeOn()"
          (save)="save()"
          (cancel)="cancel()"
          [dirty]="dirty"
          [willDenyCC]="willDenyCC"
          [willDenyCE]="willDenyCE"
        />
      </div>
    </div>
  </ng-container>
</app-head>

<!-- MOBILE ... TODO Refactor-->
@if (editMode) {
  <div
    *ngIf="api && brand && api.active"
    class="md:hidden py-4 px-8 sticky top-0 z-10 {{
          editMode
            ? 'bg-toyotaRed border-4'
            : 'bg-white'
        }}"
  >
    <div class="font-thin ">
      <app-action-buttons
        [editMode]="editMode"
        [denyMode]="denyMode"
        (edit)="edit()"
        (denyModeOn)="denyModeOn()"
        (save)="save()"
        (cancel)="cancel()"
        [dirty]="dirty"
        [willDenyCC]="willDenyCC"
        [willDenyCE]="willDenyCE"
      />
    </div>
  </div>
}

<!-- Consent: Action Buttons -->


<!-- Consent -->
<div
  *ngIf="api && api.active"
  class="mx-8 md:mx-32  flex flex-col">

  <!--  Events, Channels -->
  <div class="gap-4 flex flex-col md:flex-row">
    <div
      class="mt-4 rounded-xl py-4 px-8 shadow bg-white w-full md:w-[50%]"
    >
      <app-contact-channels-update
        [api]="apiToEdit"
        (didChange)="checkboxChannelChanged($event)"
      />
    </div>

    <div
      class="mt-4 rounded-xl py-4 px-8 shadow bg-white w-full md:w-[50%]"
    >
      <app-contact-events-update
        [api]="apiToEdit"
        (didChange)="checkboxEventChanged($event)"
      />
    </div>

    <!--   Legal MD     -->
  </div>

  <!--  Preferred, Group -->
  <div class="flex lg:flex-row flex-col">
    <div
      class="mt-4 lg:border-none rounded-xl shadow p-8 bg-white w-full"
    >
      <div class="xl:flex xl:justify-between md:gap-8">
        <app-preferred-dealer
          [preferredDealer]="api.preferredDealer"
          *ngIf="!editMode"
          [brand]="brand"
          [editMode]="editMode"
          [canChangeBrand]="api.canSwitchBrand"
          class="xl:w-1/2"
        />
        <app-preferred-dealer
          [preferredDealer]="apiToEdit.preferredDealer"
          *ngIf="editMode"
          [brand]="brand"
          [editMode]="editMode"
          class="xl:w-1/2"
        />

        <!--              <app-preferred-dealer-update-->
        <!--                [api]="apiToEdit"-->
        <!--                (didChange)="validate()"-->
        <!--                (dealerAdded)="onDealerAdded($event)"-->
        <!--                (searchInput)="handleSearchInput($event)"-->
        <!--                (focusChange)="handleFocusChange($event)"-->
        <!--                class="xl:w-1/2"-->
        <!--              />-->

        <app-preferred-dealer-update
          [api]="apiToEdit"
          [groupDealerList]="api.groupDealerList"
          [allDealerList]="api.allDealerList"
          (didChange)="validate()"
          (dealerAdded)="onDealerAdded($event)"
          class="xl:w-1/2"
        />
      </div>

      <app-group-dealer
        [api]="apiToEdit"
        [apiOriginal]="api"
        [editMode]="editMode"
        (didChange)="validate()"
      />
    </div>

    <!--   Legal   SM  -->
    <div
      class="flex lg:hidden mt-4 lg:border-b-0 rounded-xl shadow p-8 bg-white"
    >
      <app-legal-links [legalEntity]="legalEntity" [consentVersion]="api.consentVersion"/>
    </div>
  </div>
</div>

<app-contact
  [consentVersion]="api.consentVersion"
/>

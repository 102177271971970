import {Component, computed, OnInit, Signal, signal} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service} from 'ng-recaptcha';
import {
  LookupController,
} from "@app/domain/presenter/controller/lookup.controller";
import {map} from "rxjs";
import {toSignal} from "@angular/core/rxjs-interop";
import {LookupViewModel} from "@app/domain/presenter/viewmodel/lookup-view.model";
import {LookupService} from "@app/shared/services/lookup-service.service";
import {CommonModule} from "@angular/common";
import {ContactComponent} from "@app/shared/components/contact/contact.component";
import {HeadComponent} from "@app/shared/components/head/head.component";
import {ActivitySpinnerComponent} from "@app/shared/components/activity-spinner/activity-spinner.component";

@Component({
  selector: 'app-lookup',
  standalone: true,
  templateUrl: './lookup.component.html',
  providers: [
    ReCaptchaV3Service,
  ],
  imports: [CommonModule, ContactComponent, HeadComponent, ActivitySpinnerComponent]
})
export class LookupComponent implements OnInit {
  favIcon: HTMLLinkElement = document.querySelector('#favIcon');

  controller: LookupController

  vm$: Signal<LookupViewModel>
  vm = new LookupViewModel()

  constructor(
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    private lookupService: LookupService
  ) {
    // This is on purpose not injected , so the domain layer is free of angular references.
    this.controller = new LookupController({
      brandString: this.route.params.pipe(map((params) => params['brand'])),
      recaptchaToken: recaptchaV3Service.execute('token'),
      lookup: lookupService.lookup.bind(lookupService),
    });

    this.vm$ = toSignal(this.controller.viewModel$)
  }

  ngOnInit() {
    this.controller.onInit()
    console.debug("ngOnInit()", this.vm)

    this.favIcon.href = '/assets/images/' + this.vm$().brand.favicon
  }

  didClickLookupButton() {
    this.controller.lookup()
  }

  setEmail(email: string) {
    this.controller.setEmail(email)
  }
}

import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "@src/environments/environment";
import {HttpClient} from "@angular/common/http";
import {VehicleBrand} from "@app/domain/core/vehicle-brand.model";

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor(
    private http: HttpClient,
  ) { }

  lookup(email: string, token: string, brand: VehicleBrand): Observable<void> {
    const path = "/service/customer" + brand.path + "/by-email/" + email + "/" + token
    let url = environment.endpoints.lookup + path;
    return this.http.get<void>(url);
  }
}

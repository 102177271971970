export const LegalEntities = {
  ToyotaGermany: {
    name: "TOYOTA Deutschland GmbH",
    department: "Kundenbetreuung Einwilligungsverwaltung",
    city: "50420 Köln",
    phone: "+49 (0)2234 102 7575",
    email: "einwilligung@toyota.de"
  },
  ToyotaAustria: {
    name: "Toyota Austria GmbH",
    department: "Kundenbetreuung Einwilligungsverwaltung",
    city: "1100 Wien",
    phone: "0502141 150",
    email: "einwilligung@toyota.at"
  },
  LexusGermany: {
    name: "Lexus Deutschland",
    department: "Lexus Kundenbetreuung - Einwilligungsverwaltung",
    city: "50420 Köln",
    phone: "+49 (0)2234 102 7575",
    email: "einwilligung@lexus.de"
  },
  LexusAustria: {
    name: "Lexus Austria",
    department: "Lexus Kundenbetreuung Einwilligungsverwaltung",
    city: "1100 Wien",
    phone: "0502141 150",
    email: "einwilligung@lexus.at"
  }
}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'lib-v-icon',
  standalone: true,
  templateUrl: './v-icon.component.html'
})
export class VIconComponent {
  @Input() src: string;
  @Input() title: string;
}

import {lastValueFrom, Observable, Subject} from "rxjs";
import {LookupFeature, LookupFunction} from "@app/domain/usecases/features/lookup.feature";
import {LookupViewModel} from "@app/domain/presenter/viewmodel/lookup-view.model";
import {VehicleBrand} from "@app/domain/core/vehicle-brand.model";

export interface LookupControllerDependencies {
  brandString: Observable<string>
  recaptchaToken: Observable<string>
  lookup: LookupFunction;
}

export class LookupController {
  viewModel$ = new Subject<LookupViewModel>()
  viewModel = new LookupViewModel()

  userLookupFeature: LookupFeature

  token: string

  constructor(
    private dependencies: LookupControllerDependencies,
  ) {
    // This is on purpose not injected, so the domain layer is free of angular references.
    this.userLookupFeature = new LookupFeature({
      lookup: dependencies.lookup,
      token$: this.dependencies.recaptchaToken
    });
    this.dependencies.brandString.subscribe(this.setBrand.bind(this))
  }

  // Lifecycle
  async onInit() {
    this.userLookupFeature.onInit()
    this.pushViewModel();
  }

  // Actions
  async lookup() {
    this.viewModel.inProgress = true
    this.pushViewModel()
    this.viewModel.done = await this.userLookupFeature.run(this.viewModel)
    this.viewModel.inProgress = false
    this.pushViewModel();
  }

  // Helpers
  private setBrand(brandString: string) {
    this.viewModel.brand = new VehicleBrand(brandString)
    this.pushViewModel();
  }

  pushViewModel() {
    this.viewModel$.next(this.viewModel);
  }

  setEmail(email: string) {
    this.viewModel.email = email
    this.pushViewModel();
  }
}

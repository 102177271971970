import {LegalEntity} from "@app/domain/core/legal-entity.model";

export class ContactViewModel {
  constructor(
    public legalEntity: LegalEntity
  ) {
  }

  static build(): ContactViewModel {
    return new ContactViewModel(LegalEntity.ToyotaGermany)
  }
}

@if (legalEntity) {

  <app-head
    [brand]="legalEntity.vehicleBrand"
  >
    <ng-container title>{{ legalEntity.vehicleBrand.name.toUpperCase() }} Einwilligungscenter</ng-container>
    <ng-container text>
      Hier können Sie festlegen, zu welchen Kontaktanlässen und über
      welche Kontaktkanäle wir Sie kontaktieren dürfen. Außerdem können
      Sie auswählen, welche {{ legalEntity.vehicleBrand?.name ?? 'Toyota'}} Vertragspartner Sie kontaktieren dürfen
      und welcher davon Ihr bevorzugter Vertragspartner ist.
    </ng-container>
  </app-head>

  <div class="mx-8 md:mx-32  gap-4 flex flex-col md:flex-row">
    <div
      class="flex flex-col justify-between  mt-4 rounded-xl py-4 px-8 shadow bg-white w-full md:w-[50%]"
    >
      <div class="">
        <div class="customer-client-header">Ihr Zugangs-Link ist abgelaufen.</div>
        <div class="mt-8">
          <div class="error__content">
            Bitte melden Sie sich erneut an, indem sie einen
            <a class="underline" href="/lookup">
              neuen Zugangs-Link via E-Mail anfordern
            </a>
          </div>
        </div>
      </div>

      <div
        class="flex flex-col mt-5 space-y-5 md:flex-row md:space-x-5 md:justify-between md:space-y-0"
      >
        <a
          class="text-toyotaRed hover:bg-toyotaRed bg-white font-bold border rounded-full px-2 py-1  hover:text-white hover:font-bold"
          href="/lookup"
        >
          Link anfordern
        </a>

      </div>

    </div>

    <img
      class="hidden md:flex  object-cover mt-4 rounded-xl shadow bg-white w-full md:w-[50%] rounded-xl"
      src="/assets/images/header_cover1.png"
    >

  </div>

  <app-contact />
}




import { Component } from '@angular/core';
import { environment } from '@src/environments/environment';
import {RouterOutlet} from "@angular/router";
import {RecaptchaV3Module} from "ng-recaptcha";

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  imports: [RouterOutlet, RecaptchaV3Module]
})
export class AppComponent {
  constructor() {
    if (!environment.logEnabled) {
      console.log = () => {};
    }
  }
}

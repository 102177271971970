import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent} from "@app/shared/components/header/header.component";
import {VehicleBrand} from "@app/domain/core/vehicle-brand.model";

@Component({
  selector: 'app-head',
  standalone: true,
  imports: [CommonModule, HeaderComponent],
  templateUrl: './head.component.html'
})
export class HeadComponent {
  @Input() editMode = false
  @Input({required: true}) brand: VehicleBrand
}

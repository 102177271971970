<div class="customer-client-header">Kontaktkanäle</div>
<div class="mt-2 flex flex-wrap items-start gap-6 md:gap-4">
  <ng-container *ngFor="let contactEvent of api.contactChannelList; index as i">
    <div class="contact-channel-card flex items-center text-xs w-full">
      <input
        type="checkbox"
        class="w-4 h-4 accent-toyotaRed"
        [checked]="contactEvent.isConfirmed()"
        (change)="change(contactEvent, i)"
      />
      <app-event-icon
        [contactEvent]="contactEvent"
        [vertical]="true"
        (click)="change(contactEvent, i)"
      />
    </div>
  </ng-container>
</div>

import { Component, Input } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import {CommonModule} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {LegalEntity} from "@app/domain/core/legal-entity.model";

@Component({
  selector: 'app-legal-links',
  standalone: true,
  templateUrl: './legal-links.component.html',
  imports: [CommonModule, FaIconComponent],
})
export class LegalLinksComponent {
  protected readonly faChevronRight = faChevronRight;

  @Input() legalEntity: LegalEntity;
  @Input() consentVersion: string
}

export enum CountryId {
  DE = 'DE',
  AT = 'AT',
}

export class Country {
  constructor(public countryId: CountryId) {
  }
  get nameForCountryBrand(): string {
    switch (this.countryId) {
      case CountryId.DE:
        return "Germany";
      case CountryId.AT:
        return "Austria"
    }
  }

  static from(countryString: string): Country {
    if (countryString.toLowerCase().includes('at')) {
      return Country.AT;
    }

    return Country.DE;
  }

  static get DE(): Country {
    return new Country(CountryId.DE);
  }

  static get AT(): Country {
    return new Country(CountryId.AT);
  }

  get defaultConsentVersion(): string {
    switch (this.countryId) {
      case CountryId.DE:
        return "E-2023-1";
      case CountryId.AT:
        return "A-2023-1";
    }
  }

  get isAustria(): boolean {
    return this.countryId === CountryId.AT;
  }
}

import {
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { CustomerService } from '@app/shared/services/customer.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { plainToInstance } from 'class-transformer';
import { CountryCodeHelper } from '@app/shared/utils/CountryCodeHelper';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { PreferredDealerUpdateComponent } from '@app/shared/components/preferred-dealer-update/preferred-dealer-update.component';
import { GroupDealerComponent } from '@app/pages/customer/components/group-dealer/group-dealer.component';
import {VehicleBrand} from "@app/domain/core/vehicle-brand.model";
import {CustomerClientResponse, DealerWithGroup} from "@app/domain/data/api-response.data";
import {LegalEntity} from "@app/domain/core/legal-entity.model";
import {environment} from "@src/environments/environment";
import {Country} from "@app/domain/core/country.model";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {ContactComponent} from "@app/shared/components/contact/contact.component";
import {LegalLinksComponent} from "@app/shared/components/legal-links/legal-links.component";
import {DoiComponent} from "@app/pages/doi/doi.component";
import {FooterComponent} from "@app/shared/components/footer/footer.component";
import {
  ContactEventsUpdateComponent
} from "@app/pages/customer/components/contact-events-update/contact-events-update.component";
import {PreferredDealerComponent} from "@app/pages/customer/components/preferred-dealer/preferred-dealer.component";
import {
  ContactChannelsUpdateComponent
} from "@app/pages/customer/components/contact-channels-update/contact-channels-update.component";
import {ActionButtonsComponent} from "@app/pages/customer/components/action-buttons/action-buttons.component";
import {HeaderComponent} from "@app/shared/components/header/header.component";
import {CommonModule} from "@angular/common";
import {HeadComponent} from "@app/shared/components/head/head.component";

@Component({
  selector: 'app-customer',
  standalone: true,
  templateUrl: './customer.component.html',
  imports: [
    CommonModule,
    ContactComponent,
    LegalLinksComponent,
    GroupDealerComponent,
    PreferredDealerUpdateComponent,
    ContactEventsUpdateComponent,
    PreferredDealerComponent,
    ContactChannelsUpdateComponent,
    ActionButtonsComponent,
    HeadComponent,
  ]
})
export class CustomerComponent implements OnInit, OnDestroy {
  brand: VehicleBrand;
  api: CustomerClientResponse;
  apiToEdit: CustomerClientResponse;
  originalApi: CustomerClientResponse;
  showContact = false;
  editMode = false;
  denyMode = false;
  favIcon: HTMLLinkElement = document.querySelector('#favIcon');
  faChevronRight = faChevronRight;
  lastChannelChangedCheckboxIndex?: any;
  lastEventChangedCheckboxIndex?: any;

  dirty = false;
  willDenyCC = false;
  willDenyCE = false;

  typedText = '';
  inputFocused: boolean = false;

  @ViewChildren(PreferredDealerUpdateComponent)
  preferredDealerUpdateComponents: QueryList<PreferredDealerUpdateComponent>;

  @ViewChildren(GroupDealerComponent)
  groupDealerComponents: QueryList<GroupDealerComponent>;

  legalEntity = LegalEntity.ToyotaGermany
  constructor(
    private customerService: CustomerService,
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe((params) => {
      this.setCredentials(params['credentials']);
      this.setBrand(params['brand']);
    });
  }

  ngOnInit(): void {
    this.setCredentials(this.route.snapshot.params['credentials']);
    this.setBrand(this.route.snapshot.params['brand']);

    // For desktop browsers
    window.addEventListener('beforeunload', this.beforeUnloadListener);

    // Manually add listeners for mobile browsers
    document.addEventListener(
      'visibilitychange',
      this.visibilityChangeListener,
    );
    window.addEventListener('pagehide', this.pageHideListener);
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.beforeUnloadListener);
    document.removeEventListener(
      'visibilitychange',
      this.visibilityChangeListener,
    );
    window.removeEventListener('pagehide', this.pageHideListener);
  }

  private beforeUnloadListener = (event: BeforeUnloadEvent) => {
    if (this.editMode && this.dirty) {
      event.preventDefault();
      event.returnValue =
        'You have unsaved changes. Do you really want to leave?';
    }
  };

  private visibilityChangeListener = () => {
    if (document.visibilityState === 'hidden' && this.editMode && this.dirty) {
      alert('You have unsaved changes. Do you really want to leave?');
    }
  };

  private pageHideListener = (event: PageTransitionEvent) => {
    if (this.editMode && this.dirty) {
      event.preventDefault();
      alert('You have unsaved changes. Do you really want to leave?');
    }
  };

  setBrand(brand: string) {
    this.brand = new VehicleBrand(brand);
    this.legalEntity = LegalEntity.from(VehicleBrand.from(brand), Country.from(environment.country))
    this.favIcon.href = '/assets/images/' + this.brand.favicon;
    this.reload();
  }

  setCredentials(credentials: string) {
    if (credentials && credentials.length > 10) {
      localStorage.setItem('credentials', credentials);
    }
  }

  reload() {
    if (this.brand) {
      this.customerService.getCustomerConsent(this.brand).subscribe(
        (api) => {
          console.log('api', api);
          this.api = plainToInstance(CustomerClientResponse, api);
          this.originalApi = api;
          this.apiToEdit = plainToInstance(CustomerClientResponse, api);
          if (!this.api) {
            console.log('no consent found');
            this.redirectToErrorPage();
          }
        },
        (error) => {
          console.log('getCustomer error', error);
          this.redirectToErrorPage();
        },
      );
    }
  }

  redirectToErrorPage() {
    this.router.navigate(['error']);
  }

  edit() {
    this.lockWindow();
    this.editMode = true;
    this.apiToEdit = this.api;
  }

  denyModeOn() {
    this.lockWindow();
    // this.editMode = false;
    this.denyMode = true;

    this.api = this.apiToEdit;
    this.api.contactEventList.forEach((contactChannel) => {
      contactChannel.unconfirm();
    });
    this.api.contactChannelList.forEach((contactChannel) => {
      contactChannel.unconfirm();
    });
    this.validate();
  }

  save() {
    this.unlockWindow();
    this.api = this.apiToEdit;
    this.editMode = false;
    this.denyMode = false;
    this.willDenyCC = false;
    this.willDenyCE = false;

    // Save group dealer changes
    this.groupDealerComponents.forEach((component) => {
      component.saveChanges();
    });

    if (this.dirty) {
      this.customerService
        .updateCustomerConsent(
          this.brand,
          JSON.parse(JSON.stringify(this.apiToEdit)),
        )
        .subscribe((success) => {
          console.log(success);
          this.dirty = false;
          this.reload();
        });
    }
    this.preferredDealerUpdateComponents.forEach((component) => {
      component.clearInput();
    });
    // Exit edit mode after saving
    this.editMode = false;
    this.typedText = '';
  }

  cancel() {
    console.log(this.willDenyCC);
    console.log(this.willDenyCE);
    this.unlockWindow();
    this.editMode = false;
    this.denyMode = false;
    this.willDenyCE = false;
    this.willDenyCC = false;
    this.api = plainToInstance(CustomerClientResponse, this.originalApi);
    // this.validate();
    console.log('cancel');
    this.dirty = false;
    this.reload();
    this.preferredDealerUpdateComponents.forEach((component) => {
      component.clearInput();
    });
    this.typedText = '';
  }

  isCountryAustria(): boolean {
    return CountryCodeHelper.isCountryAustria();
  }

  isCountryGermany(): boolean {
    return CountryCodeHelper.isCountryGermany();
  }

  lockWindow() {
    window.addEventListener('beforeunload', this.lockWindowListener);
  }

  private lockWindowListener(e: any) {
    // Cancel the event as stated by the standard.
    e.preventDefault();
    // Chrome requires returnValue to be set.
    e.returnValue = '';
  }

  unlockWindow() {
    window.removeEventListener('beforeunload', this.lockWindowListener);
  }

  checkboxChannelChanged(index: any) {
    this.lastChannelChangedCheckboxIndex = index;
    this.validate();
  }

  checkboxEventChanged(index: any) {
    if (index === 0) {
      this.lastEventChangedCheckboxIndex = 2;
    }
    if (index === 1) {
      this.lastEventChangedCheckboxIndex = 0;
    }
    if (index === 2) {
      this.lastEventChangedCheckboxIndex = 1;
    }
    if (index === 3) {
      this.lastEventChangedCheckboxIndex = 3;
    }
    this.validate();
  }

  validate() {
    this.editMode = true;
    const originalCC = this.originalApi.contactChannelList;
    const editedCC = this.apiToEdit.contactChannelList;

    this.dirty = false;

    // Check for changes in contact channels
    if (originalCC.length !== editedCC.length) {
      this.dirty = true;
    } else {
      for (let i = 0; i < originalCC.length; i++) {
        if (originalCC[i].confirmationIcon !== editedCC[i].confirmationIcon) {
          this.dirty = true;
          break;
        }
      }
    }

    const originalCE = this.originalApi.contactEventList;
    const editedCE = this.apiToEdit.contactEventList;

    // Check for changes in contact events
    if (!this.dirty && originalCE.length === editedCE.length) {
      for (let i = 0; i < originalCE.length; i++) {
        if (originalCE[i].confirmationIcon !== editedCE[i].confirmationIcon) {
          this.dirty = true;
          break;
        }
      }
    }

    const originalDealerList = this.originalApi.groupDealerList.map(
      (dealer) => dealer.dealerNumber,
    );

    const selectedDealerNumbers =
      this.groupDealerComponents.first.getSelectedDealerNumbers();

    // Sort both arrays to ensure they are comparable
    originalDealerList.sort();
    selectedDealerNumbers.sort();

    // Compare the sorted arrays
    if (
      !this.dirty &&
      (originalDealerList.length !== selectedDealerNumbers.length ||
        !originalDealerList.every(
          (value, index) => value === selectedDealerNumbers[index],
        ))
    ) {
      this.dirty = true;
    }

    const originalPref = this.originalApi.preferredDealer;
    const editedPref = this.apiToEdit.preferredDealer;

    if (JSON.stringify(originalPref) != JSON.stringify(editedPref)) {
      this.dirty = true;
    }

    if (
      (this.inputFocused && this.typedText.length > 0) ||
      (!this.inputFocused && this.typedText.length > 0) ||
      (this.inputFocused && this.typedText.length === 0)
    ) {
      this.dirty = true;
    }

    this.editMode = this.dirty;

    this.willDenyCC = false;
    this.willDenyCE = false;
    if (editedCC.filter((c) => c.isConfirmed()).length === 0) {
      this.willDenyCC = true;
    }

    if (editedCE.filter((c) => c.isConfirmed()).length === 0) {
      this.willDenyCE = true;
    }
  }

  onDealerAdded(dealerWithGroup: DealerWithGroup) {
    this.groupDealerComponents.forEach((c) => c.onDealerAdded(dealerWithGroup));
  }
}

@if (vm(); as vm) {
  <div class="mx-8 md:mx-32 text-lightText mt-4 md:md-4 px-8 py-7 rounded-xl shadow p-8 bg-white h-full">
    <div class="customer-client-header">
      Brauchen Sie Hilfe?
    </div>

    <div class="my-2">Kontaktieren Sie uns gerne:</div>

    <div class="flex justify-between flex-row">
      <app-legal-entity [legalEntity]="vm.legalEntity" />
      <div class="hidden lg:block lg:border-b-0 lg:pb-0 w-[50%]">
        <app-legal-links [legalEntity]="vm.legalEntity" [consentVersion]="consentVersion" />
      </div>
    </div>
  </div>
  <app-footer [legalEntity]="vm.legalEntity" />
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-action-buttons',
  standalone: true,
  templateUrl: './action-buttons.component.html',
  imports: [
    CommonModule
  ]
})
export class ActionButtonsComponent {
  @Input() editMode = false
  @Input() denyMode = false
  @Input() dirty = false
  @Input() willDenyCC = false
  @Input() willDenyCE = false
  @Output() edit = new EventEmitter()
  @Output() denyModeOn =  new EventEmitter()
  @Output() save = new EventEmitter()
  @Output() cancel =  new EventEmitter()
}

@if (vm$(); as viewModel) {

  <app-head
    [brand]="viewModel.brand"
  >
    <ng-container title>{{ viewModel.brand.name.toUpperCase() }} Einwilligungscenter</ng-container>
    <ng-container text>
      Hier können Sie festlegen, zu welchen Kontaktanlässen und über
      welche Kontaktkanäle wir Sie kontaktieren dürfen. Außerdem können
      Sie auswählen, welche {{ viewModel.brand?.name ?? 'Toyota'}} Vertragspartner Sie kontaktieren dürfen
      und welcher davon Ihr bevorzugter Vertragspartner ist.
    </ng-container>
  </app-head>

  <div class="mx-8 md:mx-32 gap-4 flex flex-col md:flex-row">
    <div
      class="mt-4 rounded-xl py-4 px-8 shadow bg-white w-full md:w-[50%] "
    >
      <div class="customer-client-header">Zugangslink anfordern</div>

      <p *ngIf="!viewModel.done" class="mt-8 max-w-xs leading-5 tracking-wide  text-black ">
        <span>Bitte senden Sie uns Ihre E-Mailadresse.</span>
        <span>Sie erhalten eine E-Mail mit Ihrem persönlichen Zugangs-Link.</span>
      </p>
      <p *ngIf="viewModel.done" class="max-w-xs text-xs leading-5 tracking-wide  text-success">
        <span>Wenn uns Ihre E-Mail-Adresse bekannt ist, senden wir Ihnen nun Ihren individuellen Zugangslink zu</span>
        <span></span>
      </p>

      <div *ngIf="!viewModel.done" class="flex flex-col mt-5 space-y-5 md:flex-row md:space-x-5 md:space-y-0">
        <input
          #textfield
          class="p-2 placeholder:text-xs border border-zinc-600 rounded placeholder:md:text-left focus:outline-none"
          id="textfield"
          placeholder="E-Mail"
          type="email"
          (input)="setEmail(textfield.value)"
        >
        <button
          #button
          class="disabled:bg-gray-400 px-5 py-3 text-xs rounded-md bg-toyotaRed enabled:hover:scale-105 font-semibold text-white duration:500"
          [disabled]="viewModel.isLookupDisabled"
          id="button"
          (click)="didClickLookupButton()"
        >
          <app-activity-spinner *ngIf="viewModel.inProgress" />
          Senden
        </button>
      </div>
    </div>

    <img
      class="hidden md:flex object-cover mt-4 rounded-xl shadow bg-white w-full md:w-[50%] rounded-xl"
      src="/assets/images/header_cover1{{ viewModel.brand.isToyota() ? '' : '_lexus'}}.png"
    >

  </div>


  <app-contact />

}

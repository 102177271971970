import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import {ContactEvent, CustomerClientResponse} from "@app/domain/data/api-response.data";
import {EventIconComponent} from "@app/pages/customer/components/event-icon/event-icon.component";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-contact-events-update',
  standalone: true,
  templateUrl: './contact-events-update.component.html',
  styleUrls: ['./contact-events-update.component.scss'],
  imports: [
    CommonModule,
    EventIconComponent
  ]
})
export class ContactEventsUpdateComponent {
  @Input() api: CustomerClientResponse;
  @Output() didChange = new EventEmitter<void>();

  change(contactEvent: any, index: any) {
    if (contactEvent.isConfirmed()) {
      contactEvent.unconfirm();
    } else {
      contactEvent.confirm();
    }
    console.log('Changed checkbox:', contactEvent, 'At index:', index);
    this.didChange.emit(index);
  }

  toggleTooltip(contactEvent: ContactEvent, event: MouseEvent) {
    event.stopPropagation(); // Prevent the event from propagating to the document.

    // Check if the clicked target is the image
    if ((event.target as HTMLElement).tagName === 'IMG') {
      const isOpen = contactEvent.showTooltip;

      // Close all tooltips
      this.api.contactEventListSorted.forEach((event: ContactEvent) => {
        event.showTooltip = false;
      });

      // Toggle the clicked tooltip
      if (!isOpen) {
        contactEvent.showTooltip = true;
      }
    } else {
      // Close all tooltips when clicking outside the img tag
      this.api.contactEventListSorted.forEach((event: ContactEvent) => {
        event.showTooltip = false;
      });
    }
  }

  @HostListener('document:click', ['$event'])
  closeTooltips(event: MouseEvent) {
    this.api.contactEventListSorted.forEach((contactEvent: ContactEvent) => {
      contactEvent.showTooltip = false;
    });
  }
}

import {map, Observable} from "rxjs";
import {Country} from "@app/domain/core/country.model";
import {VehicleBrand} from "@app/domain/core/vehicle-brand.model";
import {BaseStep} from "@app/domain/usecases/base/base-step";

export interface LoadVehicleBrandStepDependencies {
  brandString: Observable<string>
}

export class LoadVehicleBrandStep extends BaseStep<LoadVehicleBrandStepDependencies>{
  provide(): Observable<VehicleBrand> {
    return this.dependencies.brandString.pipe(map(VehicleBrand.from))
  }

  run(block: (brand: VehicleBrand) => VehicleBrand) {
    this.provide().subscribe(block)
  }
}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'lib-h-icon',
  standalone: true,
  templateUrl: './h-icon.component.html'
})
export class HIconComponent {
  @Input() src: string;
  @Input() title: string;
}

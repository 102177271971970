import {EmailHelper} from "@app/shared/utils/EmailHelper";
import {VehicleBrand} from "@app/domain/core/vehicle-brand.model";
import {LookupFeatureInput} from "@app/domain/usecases/features/lookup.feature";

export class LookupViewModel implements LookupFeatureInput{
  email: string;
  brand = VehicleBrand.TOYOTA
  done = false;
  inProgress = false;

  get isLookupDisabled(): boolean {
    return !EmailHelper.isEmail(this.email);
  }
}

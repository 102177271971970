import {Country} from "@app/domain/core/country.model";
import {VehicleBrand} from "@app/domain/core/vehicle-brand.model";
import {LegalEntities} from "@app/domain/core/legal-entities";
import {environment} from "@src/environments/environment";

type CountryBrand = "ToyotaGermany" | "ToyotaAustria" | "LexusGermany" | "LexusAustria"

export interface LegalEntityValues {
  name: string
  department: string
  city: string
  phone: string
  email: string
}

export class LegalEntity {
  name: string
  department: string
  city: string
  phone: string
  email: string
  countryBrand: CountryBrand;
  constructor(
    public country: Country,
    public vehicleBrand: VehicleBrand
  ) {
    this.countryBrand = (vehicleBrand.nameForCountryBrand + country.nameForCountryBrand) as CountryBrand
    const values = LegalEntities[this.countryBrand]
    this.name = values.name
    this.department = values.department
    this.city = values.city
    this.phone = values.phone
    this.email = values.email
  }

  static get ToyotaGermany(): LegalEntity {
    return new LegalEntity(Country.DE, VehicleBrand.TOYOTA)
  }

  // static from(brandString: string, countryString: string): LegalEntity {
  //   return new LegalEntity(Country.from(countryString), new VehicleBrand(brandString))
  // }

  static from(brand: VehicleBrand, country: Country): LegalEntity {
    return new LegalEntity(country, brand)
  }

  consentLink(consentVersion: string) {
    if (!consentVersion) {
      consentVersion = this.country.defaultConsentVersion
    }
    let url = environment.eweUrl;
    if (this.vehicleBrand.isToyota()) {
      url = url.replace(/\/(toyota)\//, "/lexus/");
    }
    return url.replace('{consentVersion}', consentVersion);
  }

  dsiLink(consentVersion: string) {
    if (!consentVersion) {
      consentVersion = this.country.defaultConsentVersion
    }
    let url = environment.dsiUrl;
    if (!this.vehicleBrand.isLexus()) {
      url = url.replace(/\/(toyota)\//, "/lexus/");
    }
    return url.replace('{consentVersion}', consentVersion);
  }
}

<div class="lg:hidden customer-client-header">Rechtliche Details</div>

<div class="flex flex-col xl:flex-row justify-between">
  <div class="w-full xl:w-[50%] mt-2 xl:pr-8 pr-2">
    Hier können Sie den Wortlaut Ihrer Einwilligungserklärung und die
    Datenschutzinformationen im Detail durchlesen.
  </div>
  <div class="w-full xl:w-[50%]">
    <div
      class="mt-4 text-lg hover:cursor-pointer hover:underline md:mt-2 xl:mt-1"
    >
      <a [href]="legalEntity.consentLink(consentVersion)" target="_blank"
      >Einwilligungserklärung
        <fa-icon [icon]="faChevronRight"></fa-icon>
      </a>
    </div>
    <div class="mt-5 text-lg hover:cursor-pointer hover:underline">
      <a [href]="legalEntity.dsiLink(consentVersion)" target="_blank"
      >Datenschutzinformation
        <fa-icon [icon]="faChevronRight"></fa-icon>
      </a>
    </div>
  </div>
</div>

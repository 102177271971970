import { Expose, Type } from 'class-transformer';
import 'reflect-metadata';

export interface DealerInformation {
  groupDealerList: Dealer[];
  allDealerList: DealerWithGroup[];
}

export class CustomerClientResponse {
  name: string;

  @Type(() => ContactEvent)
  contactEventList: ContactEvent[];

  @Type(() => ContactEvent)
  contactChannelList: ContactEvent[];

  @Type(() => Dealer)
  preferredDealer: Dealer;

  @Type(() => Dealer)
  groupDealerList: Dealer[];

  @Type(() => DealerWithGroup)
  allDealerList: DealerWithGroup[];

  @Type(() => Dealer)
  requestingDealer: Dealer;

  canSwitchBrand = true;

  consentVersion: string;

  active: boolean;

  get contactEventListSorted(): ContactEvent[] {
    let events = this.getContactEvent('EVENTS');
    let reminder = this.getContactEvent('REMINDER');
    let offers = this.getContactEvent('OFFERS');
    let surveys = this.getContactEvent('SURVEYS');

    return [reminder, events, offers, surveys];
  }

  getContactEvent(key: string) {
    for (let e of this.contactEventList) {
      if (e.eventName === key) {
        return e;
      }
    }
    return null;
  }
}

export class Customer {
  toyota: boolean;
  lexus: boolean;
}

export class ContactEvent {
  eventName: string;
  displayName: string;
  tooltip: string;
  confirmationIcon: string;
  customerDescription: string;
  showTooltip: boolean = false;

  @Expose()
  isConfirmed(): boolean {
    // console.log('confirmed', this.displayName, this.confirmationIcon === 'box-checked-green')
    return this.confirmationIcon === 'box-checked-green';
  }

  unconfirm() {
    this.confirmationIcon = 'box-unchecked-red';
  }

  confirm() {
    this.confirmationIcon = 'box-checked-green';
  }
}

export class DealerWithGroup {
  dealerNumber: string;
  name: string;
  street: string;
  postcode: string;
  city: string;
  dealerGroup: string;
  groupName: string;
  fullCompanyName: string;
}

export class Dealer {
  dealerNumber: string;
  dealerGroup: string;
  name: string;
  street: string;
  postcode: string;
  city: string;
  fullCompanyName: string;
  groupName: string;

  static createDealerWithGroup(dealerWithGroup: DealerWithGroup) {
    let dealer = new Dealer();
    dealer.dealerNumber = dealerWithGroup.dealerNumber;
    dealer.name = dealerWithGroup.name;
    dealer.postcode = dealerWithGroup.postcode;
    dealer.street = dealerWithGroup.street;
    dealer.city = dealerWithGroup.city;
    dealer.fullCompanyName = dealerWithGroup.fullCompanyName || '';
    return dealer;
  }

  static create(name: string, dealerNumber: string) {
    let dealer = new Dealer();

    dealer.dealerNumber = dealerNumber;
    dealer.name = name;
    return dealer;
  }
}

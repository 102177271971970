import {environment} from "../../../environments/environment";


export class CountryCodeHelper {

  static isCountryGermany(): boolean {
    return environment.country === 'DE';
  }

  static isCountryAustria(): boolean {
    return environment.country === 'AT';
  }
}

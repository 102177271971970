import {provideRouter, Routes} from "@angular/router";
import {ApplicationConfig} from "@angular/core";
import {ErrorComponent} from "@app/pages/error/error.component";
import {CustomerComponent} from "@app/pages/customer/customer.component";
import {LookupComponent} from "@app/pages/lookup/lookup.component";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {credentialsInterceptor} from "@app/main/interceptors/credentials.interceptor";
import {RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service} from "ng-recaptcha";
import {environment} from "@src/environments/environment";
import {NewComponent} from "@app/pages/new/new.component";
import {DoiComponent} from "@app/pages/doi/doi.component";


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ErrorComponent
      },
      {
        path: 'error',
        component: ErrorComponent,
      },
      {
        path: 'customer/:brand/:credentials',
        component: CustomerComponent,
      },
      {
        path: 'customer/:brand',
        component: CustomerComponent,
      },
      {
        path: 'lookup/:brand',
        component: LookupComponent,
      },
      {
        path: ':brand/lookup',
        component: LookupComponent,
      },

      {
        path: 'lookup',
        component: LookupComponent,
      },

      {
        path: 'doi/:verificationtoken',
        component: DoiComponent,
      },
      {
        path: 'doi/:brand/:verificationtoken',
        component: DoiComponent,
      },
      {
        path: ':brand/doi/:verificationtoken',
        component: DoiComponent,
      },

      // {
      //   path: 'new',
      //   component: NewComponent,
      // },
      // {
      //   path: 'new/:brand',
      //   component: NewComponent,
      // },
    ]
  }
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([credentialsInterceptor])),
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.sitekey }
  ]
}

import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ContactComponent} from "@app/shared/components/contact/contact.component";
import {HeadComponent} from "@app/shared/components/head/head.component";
import {LegalEntity} from "@app/domain/core/legal-entity.model";
import {ActivitySpinnerComponent} from "@app/shared/components/activity-spinner/activity-spinner.component";

@Component({
  selector: 'app-error',
  standalone: true,
  templateUrl: './error.component.html',
  imports: [CommonModule, ContactComponent, HeadComponent, ActivitySpinnerComponent]
})
export class ErrorComponent {
  legalEntity= LegalEntity.ToyotaGermany
}

import {Component, Input, signal} from '@angular/core';
import { CommonModule } from '@angular/common';
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {LegalEntity, LegalEntityValues} from "@app/domain/core/legal-entity.model";

// Switch to explicit ViewModel when needed
type LegalEntityViewModel = LegalEntityValues
// interface LegalEntityViewModel {
//   name: string
//   department: string
//   city: string
//   phone: string
//   email: string
// }

@Component({
  selector: 'app-legal-entity',
  standalone: true,
  imports: [CommonModule, FaIconComponent],
  templateUrl: './legal-entity.component.html'
})
export class LegalEntityComponent {
  protected readonly faChevronRight = faChevronRight;
  @Input({required: true}) legalEntity: LegalEntityViewModel;
}

@if (legalEntity) {

  <app-header
    [brand]="legalEntity.vehicleBrand"
  />

  <div class="mt-8 mx-8 md:mx-32  gap-4 flex flex-col md:flex-row md:justify-center">
    <div class="flex flex-col justify-between mt-4 rounded-xl py-4 px-8 shadow bg-white w-full md:w-[50%] ">
      <div class="">
        <div class="customer-client-header">
          {{ focusConfirm ? 'Einwilligen?' : 'Ablehnen?'}}
        </div>
        <div class="mt-8">
          <span *ngIf="!done">
            {{ focusConfirm ? 'Bitte bestätigen Sie Ihre Einwilligung.' : 'Möchten Sie wirklich keine Einwilligung abgeben? So entgehen Ihnen möglicherweise relevante Informationen, die wir Ihnen nicht zusenden dürfen.'}}
          </span>

          <span *ngIf="done && success" class="font-semibold text-success">
            Vielen Dank für Ihre Rückmeldung
          </span>

          <span *ngIf="done && !success" class="font-semibold  text-warning" >
            Der Link war bereits abgelaufen
          </span>
        </div>
      </div>

      <div
        *ngIf="!done"
        class="{{ focusConfirm ? 'md:flex-row' : ' md:flex-row md:flex-row-reverse'}} flex flex-col mt-5 space-y-5  md:space-x-5 md:justify-between md:space-y-0"
      >
        <button
          class="{{ focusConfirm ? 'text-toyotaRed hover:bg-toyotaRed ' : 'text-g-gray-400 hover:bg-gray-400 '}} bg-white font-bold border rounded-full px-2 py-1  hover:text-white hover:font-bold"
          (click)="confirm()"
          [disabled]="inProgress"
        >
          <app-activity-spinner *ngIf="confirmInProgress" />

          {{ focusConfirm ? 'Bestätigen' : 'Doch einwilligen'}}
        </button>
        <button
          class="{{ focusDeny ? 'text-toyotaRed hover:bg-toyotaRed' : 'text-g-gray-400 hover:bg-gray-400'}} bg-white font-bold border rounded-full px-2 py-1  hover:text-white hover:font-bold"
          (click)="deny()"
          [disabled]="inProgress"
        >
          <app-activity-spinner *ngIf="denyInProgress" />
          Ablehnen
        </button>
      </div>
    </div>

  </div>

  <app-footer [legalEntity]="legalEntity" />
}

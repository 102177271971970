import {Component, Input, OnInit, Signal} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {VehicleBrand} from "@app/domain/core/vehicle-brand.model";
import {LegalLinksComponent} from "@app/shared/components/legal-links/legal-links.component";
import {LegalEntityComponent} from "@app/shared/components/legal-entity/legal-entity.component";
import {LegalEntity} from "@app/domain/core/legal-entity.model";
import {ActivatedRoute} from "@angular/router";
import {ContactController} from "@app/domain/presenter/controller/contact.controller";
import {environment} from "@src/environments/environment";
import {map, Observable, of} from "rxjs";
import {ContactViewModel} from "@app/domain/presenter/viewmodel/contactViewModel";
import {toSignal} from "@angular/core/rxjs-interop";
import {FooterComponent} from "@app/shared/components/footer/footer.component";

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [CommonModule, FaIconComponent, LegalLinksComponent, LegalEntityComponent, FooterComponent],
  templateUrl: './contact.component.html',
})
export class ContactComponent implements OnInit {

  @Input() consentVersion: string

  controller: ContactController
  vm: Signal<ContactViewModel>

  constructor(
    private route: ActivatedRoute,
  ) {

    this.controller = new ContactController({
      brandString: this.route.params.pipe(map((params) => params['brand'])),
      countryString: of(environment.country),
    })
    this.vm = toSignal(this.controller.viewModel$)
  }

  ngOnInit() {
    this.controller.onInit()
  }
}
